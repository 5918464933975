@import './variables.scss';

$footer-fg: $gray-100;

.footer {
    margin-top: 2em;
    padding-top: 3em;
    padding-bottom: 6em;
    color: $footer-fg;
    line-height: 1.5;

    background-color: $gray-700;

    ul {
        list-style-type: none;
        padding: 0
    }

    li {
        margin-top: 0.3em;
    }

    strong {
        color: white;
    }

    a {
        color: $footer-fg;
        text-decoration: underline;

        &:hover {
            color: shift-color($footer-fg, $link-shade-percentage);
        }
    }
}

.logo {
    max-width: 250px;
    margin-bottom: 2em;
}

.love {
    color: $red;
}

.copyright {
    font-size: 0.9em;
    margin-bottom: 0.2em;

    a {
        text-decoration: underline;
    }
}

.printFooter {
    margin-top: 2em;
    color: $gray-700;
    font-size: 0.9em;
}

.footerImprint {
    border-top: 1px solid $gray-200;
    padding-top: 1em;
}