@import "./variables.scss";

.changeCard {
    margin-bottom: 1 * $spacer;
}

.changeCardTitle {
    margin-top: 0;
}

.changeTableHeader {
    width: 30%;
}