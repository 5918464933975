@import './variables.scss';

.infoTable {
    width: 100%;
    text-align: left;
    vertical-align: top;

    :global(th) {
        width: 30%;
    }
}

.keyColumn {
    white-space: nowrap !important;
    width: 20%;
}

.issuerColumn {
    // white-space: nowrap !important;
    width: 20%;
}

.targetsColumn {
    max-width: 20%;
}

.programExplainer {
    :global(.alert) {
        margin-top: 1em;
        background-color: $blue-600;

        @media print {
            background-color: $gray-200;
        }

        p {
            color: $white;

            @media print {
                color: $gray-800;
            }
        }
    }
}