@import "./variables.scss";

.sideText {
    color: $text-muted;

    @media screen {
        padding-left: 1rem;
    }
}


.articleList {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    li {
        margin-bottom: 1em;
        break-inside: avoid;
        display: flex;
    }

    p {
        margin-bottom: 0.2em;
    }
}

.articleListTitle {
    @include heading-font;
    font-size: 1.2em;

    a {
        color: $blue-fg;
    }
}

.articleListImage {
    flex-direction: column;
    margin-right: 1em;
    // width: 20%;
}

.articleListContent {
    // width: 80%;
}

.articleTags {
    // color: $text-muted;
    // font-size: 0.8em;
    // margin-left: 0.5em;
}

.articleListSummary {
    // color: $text-muted;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: 0;
}

.articleBody {
    margin-bottom: 1em;
}