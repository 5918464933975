@import "./variables.scss";


.itemList {
    list-style-type: square;
    padding-left: 1em;
}

.itemList li {
    // list-style-type: none;
    margin-bottom: $spacer * 0.25;

}

.itemId {
    color: $gray-300;
}

.page {
    font-size: 1.05em;
}

.question {
    margin-top: 0;
}

.updated {
    color: $text-muted;
    font-size: 0.8em;
}