.hiddenSubmit {
    display: none;
}

.credentialTable {
    width: 100%;
}

.credentialCell {
    padding: 0.5rem 0.5rem;
}