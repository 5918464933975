@import './variables.scss';

// main search

.searchNotice {
    color: $text-muted;
    font-size: 0.9em;
}

.searchMeta {
    padding-top: $spacer;
}


// results
.resultList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.resultItem {
    break-inside: avoid;
}

.resultTitle {
    font-size: 1.1em;
    font-weight: 600;

    a {
        color: $theme-blue-logo;
        text-decoration: none;
    }
}

.resultDetails {
    color: $text-muted;
}

// filter tags
.tagsSection {
    color: $text-muted;
    margin-bottom: 0.2em;
}

.tagsButton {
    cursor: pointer;
}

// search facets
.facet {
    :global(.card) {
        margin-bottom: 1em;
        border-radius: 0;
        border: 0px solid $blue-300;
    }

    :global(.card-header) {
        font-weight: bold;
        color: $blue-600;
        background-color: $blue-200;
        border-radius: 0 !important;
        border-bottom: 0;
    }

    break-inside: avoid;
}


.facetCount {
    float: right;
    // color: $text-muted;
}

.facetListItem {
    cursor: pointer;
}

.facetLabel {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.showAll {
    cursor: pointer;
    color: $blue-600;
}
