.accountInfo {

    td,
    th {
        vertical-align: center;
    }
}

.newCredential {
    float: right;
}

.filterUsage {
    float: right;
}