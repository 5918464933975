@import './variables.scss';

.pepTable {
  thead th {
    // Allow heading top to align with section heading on wide viewport
    padding-top: 0px;
  }
}

.flagContainer {
  display: inline-block;

  img {
    width: 100px;
    padding-right: $spacer;
  }
}

.headingContainer {
  display: inline-block;
}

.hero {
  
  background-color: $theme-blue-bg-light;
  padding-top: $spacer * 2.5;
  padding-bottom: $spacer * 3;
}

.content {
  padding-top: $spacer;
}

.nav {
  padding-top: $spacer * 3;
}