@import "./variables.scss";

.markdown {
  table {
    --bs-table-border-color: var(--bs-border-color);
    border-color: var(--bs-table-border-color);
    margin-bottom: 1em;
    width: 100%;
  }

  th,
  td {
    padding: 0.25rem;
    border-color: inherit;
    vertical-align: top;
  }

  tr {
    border-width: var(--bs-border-width) 0;
    border-color: inherit;
  }
}

.footer {
  margin-top: 2 * $spacer;
  background-color: $blue-100;
  border-radius: $border-radius;
  padding: $spacer;
}

.footerAction {
  margin-right: 0.5em;
}